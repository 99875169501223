@import url('https://fonts.googleapis.com/css?family=Nunito|Oswald');

.body {
  padding-bottom: 6vh;
}

.page_body {
  margin: 2vh auto 0px;
  width: 90%;
  background-color: #eee;
  box-shadow: 0vh 0vh 2.5vh 0.5vh rgba(0,0,0,0.5);
  font-family: 'Nunito', sans-serif;
}

.page_subHeading {
  font-size: 6vw;
  padding: 0 2vw;
  color: #fff;
  background-color: #000;
  /*border-radius: 0.5vw*/
  font-family: 'Oswald', sans-serif;
}

.page_section {
  margin: 0 0 0 20%;
  width: 75%;
}

.page_block_wrapper {
  padding: 1vw;
  display: flex;
  align-items:center;
  border-style: solid;
  margin:0.5vw 0;
  border-radius: 0.5vw;
  background-color: #fff;
}

.bottom_pad {
  padding-bottom: 1vw;
}

.img_rpd {
  height: 20vh;
  width: auto;
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-height: 500px;
  min-height: 100px;
}
