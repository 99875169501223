@import url(https://fonts.googleapis.com/css?family=Nunito|Oswald);
@import url(https://fonts.googleapis.com/css?family=Nunito|Oswald);
.body {
  padding-bottom: 6vh;
}

.page_body {
  margin: 2vh auto 0px;
  width: 90%;
  background-color: #eee;
  box-shadow: 0vh 0vh 2.5vh 0.5vh rgba(0,0,0,0.5);
  font-family: 'Nunito', sans-serif;
}

.page_subHeading {
  font-size: 6vw;
  padding: 0 2vw;
  color: #fff;
  background-color: #000;
  /*border-radius: 0.5vw*/
  font-family: 'Oswald', sans-serif;
}

.page_section {
  margin: 0 0 0 20%;
  width: 75%;
}

.page_block_wrapper {
  padding: 1vw;
  display: flex;
  align-items:center;
  border-style: solid;
  margin:0.5vw 0;
  border-radius: 0.5vw;
  background-color: #fff;
}

.bottom_pad {
  padding-bottom: 1vw;
}

.img_rpd {
  height: 20vh;
  width: auto;
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-height: 500px;
  min-height: 100px;
}

.about_block_image {
  height: 10vh;
  width: auto;
  display: inline-block;
  border-style: solid;
  border-radius: 1vw;
  max-height: 120px;
  min-height: 50px;
  background-color: #000;
  border-color: #000;
}

.about_block_info {
  display: inline-block;
  padding: 0 1vh;
  width: 100%;
}

.about_block_info_head {
  width: 100%;
  display: inline-block;
  border-bottom: solid;

}

.about_block_info_head_institution {
  float: left;
  font-size: 4vw;
  font-family: 'Oswald', sans-serif;
}
.about_block_info_head_institution a {
  text-decoration: none;
  color: #000;
}

.about_block_info_head_dates {
  float: right;
  font-size: 3vw;
  padding: 0.5vw;
  font-family: 'Oswald', sans-serif;
}

.about_block_info_description_main {
  font-size: 2.5vw;
  font-family: 'Nunito', sans-serif;
  font-weight: bold;
  text-align: center;
}

.about_block_info_description_details {
  font-size: 2vw;
  padding: 0 4vw;
  text-indent:-2.5vw;
  font-family: 'Nunito', sans-serif;
}
.footer_body {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 6vh;
  background-color: #000;
  color: #fff;
  padding-left: calc(100vw - 100%);
  z-index: 2;
  min-height: 30px;
  max-height: 75px;
}

.social_block {
  height: 100%;
  padding-top: 1vh;
  padding-right: 2vh;
  float: right;
  display: block;
}

.social_link {
  display: inline-block;
  padding: 0 0.5vh;
  height: 100%;
  width: auto;
}

.social_logo {
  height: 75%;
  width: auto;
}

.logo {
  display: block;
  margin-left: auto;
  margin-right: auto;
  height: 20vh;
  width: auto;
  min-height: 100px;
  max-height: 200px;
  padding: 1vh;
  background-color: #000;
  opacity: 0.75;
}

.nav_bar{
    list-style:none;
    margin:0;
    padding:0 0 0 calc(100vw - 100%);
    text-align:center;
    background-color: #eee;
    z-index: 3;
}

.nav_item{
    display:inline-block;
    padding:1vw;
    font-size: 2vw;
    font-family: 'Nunito', sans-serif;
}

a {
    text-decoration: none;
}

.nav_link {
    color: #000;
}

.nav_link_active {
    color: #FF0000;
    font-size: 3vw;
}

/* Set min sizes of elements here (mostly for mobile) */
/*@media screen and (max-width: 720px) {
  .nav_item {
        font-size: 14px;
    }

    .nav_link_active {
        font-size: 20px;
    }
}*/

 /*Set maximum sizes of elements here (mostly for desktop users)*/
/*@media screen and (min-width: 1200px) {
    .nav_item {
        font-size: 24px
    }

    .nav_link_active {
        font-size: 36px;
    }
}*/
body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  box-sizing: border-box;
}

