.about_block_image {
  height: 10vh;
  width: auto;
  display: inline-block;
  border-style: solid;
  border-radius: 1vw;
  max-height: 120px;
  min-height: 50px;
  background-color: #000;
  border-color: #000;
}

.about_block_info {
  display: inline-block;
  padding: 0 1vh;
  width: 100%;
}

.about_block_info_head {
  width: 100%;
  display: inline-block;
  border-bottom: solid;

}

.about_block_info_head_institution {
  float: left;
  font-size: 4vw;
  font-family: 'Oswald', sans-serif;
}
.about_block_info_head_institution a {
  text-decoration: none;
  color: #000;
}

.about_block_info_head_dates {
  float: right;
  font-size: 3vw;
  padding: 0.5vw;
  font-family: 'Oswald', sans-serif;
}

.about_block_info_description_main {
  font-size: 2.5vw;
  font-family: 'Nunito', sans-serif;
  font-weight: bold;
  text-align: center;
}

.about_block_info_description_details {
  font-size: 2vw;
  padding: 0 4vw;
  text-indent:-2.5vw;
  font-family: 'Nunito', sans-serif;
}