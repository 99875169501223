.footer_body {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 6vh;
  background-color: #000;
  color: #fff;
  padding-left: calc(100vw - 100%);
  z-index: 2;
  min-height: 30px;
  max-height: 75px;
}

.social_block {
  height: 100%;
  padding-top: 1vh;
  padding-right: 2vh;
  float: right;
  display: block;
}

.social_link {
  display: inline-block;
  padding: 0 0.5vh;
  height: 100%;
  width: auto;
}

.social_logo {
  height: 75%;
  width: auto;
}
