.nav_bar{
    list-style:none;
    margin:0;
    padding:0 0 0 calc(100vw - 100%);
    text-align:center;
    background-color: #eee;
    z-index: 3;
}

.nav_item{
    display:inline-block;
    padding:1vw;
    font-size: 2vw;
    font-family: 'Nunito', sans-serif;
}

a {
    text-decoration: none;
}

.nav_link {
    color: #000;
}

.nav_link_active {
    color: #FF0000;
    font-size: 3vw;
}

/* Set min sizes of elements here (mostly for mobile) */
/*@media screen and (max-width: 720px) {
  .nav_item {
        font-size: 14px;
    }

    .nav_link_active {
        font-size: 20px;
    }
}*/

 /*Set maximum sizes of elements here (mostly for desktop users)*/
/*@media screen and (min-width: 1200px) {
    .nav_item {
        font-size: 24px
    }

    .nav_link_active {
        font-size: 36px;
    }
}*/