@import url('https://fonts.googleapis.com/css?family=Nunito|Oswald');

.logo {
  display: block;
  margin-left: auto;
  margin-right: auto;
  height: 20vh;
  width: auto;
  min-height: 100px;
  max-height: 200px;
  padding: 1vh;
  background-color: #000;
  opacity: 0.75;
}
